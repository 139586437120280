import formatNumber from '../../format/number'
import { getRowQuickSearchString } from '../campaigns/getRowQuickSearchString'

export const assignRowDetails = ({
  row,
  selectedIds,
  userEmail: myEmail,
  userId,
}) => {
  const isMyAssignment = (
    row.adops_assignment_id === userId
  )
  const { claimedBy, claimed, sealed, sealedBy, ignores } = row
  const budgetYLeftoverPercent5Inc = formatNumber.nearestNumber(
    row.leftover_percent_y, 5
  )
  const isSelected = selectedIds.includes(row.campaign_id)
  const notesForMe = row.notes.filter(({
    shared,
    userEmail: author
  }) => (shared || author === myEmail))
  const ignoredByAll = ignores.find(({ userEmail }) => userEmail === 'all')
  const ignoreByMe = (
    ignoredByAll ||
    ignores.find(({ userEmail }) => userEmail === myEmail)
  )
  const ignoredByMe = !!ignoreByMe
  const ignoredByMeAndOthers = (
    ignoredByAll || (ignoredByMe && ignores.length > 1)
  )
  const ignoreLabel = (
    ignoredByAll
      ? 'By all'
      : (
          ignoredByMeAndOthers
            ? 'Me and others ignored'
            : (
                ignoredByMe
                  ? 'By me'
                  : (
                      ignores.length ? 'By others' : 'Unignored'
                    )
              )
        )
  )
  const claimedByMe = (claimed && claimedBy === myEmail)
  const sealedByMe = (sealed && sealedBy === myEmail)
  const spentToday = (row.spent_perc_topped_t >= 99)
  const servingLabel = (
    spentToday
      ? 'Spent'
      : (row.serving_current_hour ? 'Yes' : 'No')
  )
  const hasReminder = !!row.reminder

  return {
    ...row,
    attention: ignoreLabel,
    budgetYLeftoverPercent5Inc,
    claimedByMe,
    hasReminder,
    id: row.campaign_id,
    ignoredByAll,
    ignoreByMe,
    ignoredByMe,
    ignoredByMeAndOthers,
    is21Ui: (row.is21L ? 'Yes' : 'No'),
    is42Ui: (row.is42L ? 'Yes' : 'No'),
    isMyAssignment,
    isSelected,
    noteCount: notesForMe.length,
    notes: notesForMe,
    quickSearchString: getRowQuickSearchString(row),
    sealedByMe,
    servingLabel,
    sortVal: 0,
  }
}
