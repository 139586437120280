const formatString = require('../../format/string')

export function assignCampaignHistory({ campaign, userEmail, today }) {
  const todayEdits = campaign.trackedEdits.filter(({
    day
  }) => (today === day))
  const yesterdayEdits = campaign.trackedEdits.filter(({
    day
  }) => ((today - 1) === day))
  const historyLabels = []
  const historyStories = []
  const todayStoryLabels = []
  const yesterdayStoryLabels = []
  const todayPerOwner = {
    me: todayEdits.filter(({ userEmail: author }) => author === userEmail),
    other: todayEdits.filter(({ userEmail: author }) => author !== userEmail)
  }
  const yesterdayPerOwner = {
    me: yesterdayEdits.filter(({ userEmail: author }) => author === userEmail),
    other: yesterdayEdits.filter(({ userEmail: author }) => author !== userEmail)
  }

  todayEdits.forEach(e => {
    todayStoryLabels.push(`- ${formatString.standardiseString(e.category)} by ${e.userEmail}`)
  })
  yesterdayEdits.forEach(e => {
    yesterdayStoryLabels.push(`- ${formatString.standardiseString(e.category)} by ${e.userEmail}`)
  })

  if (todayEdits.length) {
    const ownerLabels = []

    if (todayPerOwner.me.length) {
      ownerLabels.push(
        `${todayPerOwner.me.length} me`
      )
    }
    if (todayPerOwner.other.length) {
      ownerLabels.push(
        `${todayPerOwner.other.length} o.`
      )
    }

    const label = (
      ownerLabels.length ? `T (${ownerLabels.join(', ')})` : ''
    )
    historyLabels.push(label)
    historyStories.push(
      `${todayEdits.length} edits today: ` +
      `\n${todayStoryLabels.join('\n')}\n`
    )
  }

  if (yesterdayEdits.length) {
    const ownerLabels = []

    if (yesterdayPerOwner.me.length) {
      ownerLabels.push(
        `${yesterdayPerOwner.me.length} me`
      )
    }
    if (yesterdayPerOwner.other.length) {
      ownerLabels.push(
        `${yesterdayPerOwner.other.length} o.`
      )
    }

    const label = (
      ownerLabels.length ? `Y (${ownerLabels.join(', ')})` : ''
    )
    historyLabels.push(label)
    historyStories.push(
      `${yesterdayEdits.length} edits yesterday: ` +
      `\n${yesterdayStoryLabels.join('\n')}\n`
    )
  }

  campaign.historyLabel = (
    historyLabels.length > 0 ? historyLabels.join(', ') : 'None'
  )
  campaign.historyText = (
    historyStories.length > 0
      ? historyStories.join('\n')
      : 'No edits today or yesterday.'
  )

  return campaign
}
