import Ui from './SheetUi.vue'
import { SheetCombinedStore } from './SheetCombinedStore'
import { mountVuePage } from '../mountVuePage'
import { routes } from './routes'

mountVuePage({
  faviconName: 'sheet',
  rootComponent: Ui,
  routes,
  socketComponents: ['sheet'],
  store: SheetCombinedStore,
  title: 'Sheet',
})
