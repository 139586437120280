import formatNumber from '../../../format/number'
import formatString from '../../../format/string'

const dollarFormatter = v => formatString.dollar(formatNumber.fPrec(v))

/**
 * Returns a formatted cell value with progress bar
 */

export default ({
  absolute,
  relative,
  className = 'cell-progress-bar',
  backgroundColor = '#eee',
  height = 3,
  plainText = false
}) => {
  if (plainText) {
    return `${dollarFormatter(absolute)} | ${relative}%`
  }
  const width = (
    relative >= 0
      ? (
          relative > 100 ? 100 : relative
        )
      : 0
  )

  return (
    `<span>${dollarFormatter(absolute)}, ${relative}%</span>` +
    `<div class="${className}" style="position:relative;bottom:${height}px;` +
    `height:${height}px;width:100%;background-color:${backgroundColor};">` +
    `<div style="height:100%;width:${width}%;"></div>` +
    `</div>`
  )
}
