import { server } from '../../server'
import attributeActions from './attribute'
import campaignClaims from './claims'
import { removeAll as removeListedDomains } from './listedDomains'
import * as notes from './notes'
import * as edits from './edits'
import * as margins from './margins'
import * as spend from './spend'
import * as keywords from './keywords'

export default {
  ...attributeActions,
  ...campaignClaims,
  edits,
  keywords,
  margins,
  notes,
  spend,
  acknowledgeReport(reportId) {
    return server.get(`/report/${reportId}/acknowledge`)
  },
  actionRecommendation({ campaignId, name, action, type }) {
    return server.post(
      `/campaign/${campaignId}/recommendation/${action}`,
      {
        recommendation: {
          name,
          type,
        },
      },
    )
  },
  getAffiliatedCampaigns({ campaignId, campaignIds = [campaignId] }) {
    return server.post('/campaigns/affiliates', {
      campaignIds,
    })
  },
  getAnalysis(campaignId) {
    return server.get(`/campaign/${campaignId}/recommendations`)
  },
  getCampaignDetails(campaignId, quick) {
    return server.get(`/campaign/${campaignId}/details`, {
      params: { quick },
    })
  },
  async getMultiCampaignDetails({ campaignIds, ...options }) {
    return server.post('/campaigns/details', {
      campaignIds,
      ...options,
    })
  },
  async getOptionDetails({ campaignId, ...options }) {
    return server.post(`/campaign/${campaignId}/details`, {
      ...options,
    })
  },
  getDaypartingPerformance({ campaignId, daysBack, goal }) {
    return server.get(
      `/campaign/${campaignId}/dayparting/${daysBack}`,
      { params: { goal } },
    )
  },
  getReport(options) {
    return server.get(`/campaigner/report`, {
      params: options,
    })
  },
  getSpendPlotData(campaignId) {
    return server.get('/campaigns/spend_plots', {
      params: { campaignId },
    })
  },
  getTelemetry(campaignId) {
    return server.get(`/campaign/${campaignId}/telemetry`)
  },
  removeDayparting(campaignId) {
    return server.delete(`/campaign/${campaignId}/dayparting`)
  },
  removeListedDomains,
  updateDayparting({ campaignId, dayparting }) {
    return server.put(`/campaign/${campaignId}/dayparting`, {
      dayparting,
    })
  },
}
