import { sendMessageToSocket } from '../sendMessageToSocket'
import { getSocket } from '../vueSockets/getSocket'
import Logger from '../log'

export const SocketStore = (component = 'common') => {
  const logger = Logger(`sockets/${component}`)
  const socketActionNamePrefix = `socket:${component}_`
  logger.info('initialized')

  return {
    state() {
      return {
        component,
        currentUsers: [],
        socketConnected: false,
        socketId: null,
      }
    },
    getters: {
      id: state => state.socketId,
    },
    mutations: {
      currentUsers(state, payload) {
        state.currentUsers = (payload instanceof Array ? payload : [])
      },
      socketConnected(state, payload) {
        state.socketConnected = payload
      },
      socketId(state, payload) {
        state.socketId = payload
      },
    },
    actions: {
      requestCurrentUsers({ dispatch }) {
        logger.info('requestCurrentUsers')
        return dispatch('send', {
          channel: 'get_current_users'
        })
      },
      send(_context, data) {
        logger.info(`send`, data)
        return sendMessageToSocket({
          channel: (data.channel || 'sheetIo'),
          component,
          data,
        })
      },
      [`${socketActionNamePrefix}currentUsers`]: {
        root: true,
        handler({ commit }, payload) {
          commit('currentUsers', payload)
        }
      },
      [`${socketActionNamePrefix}disconnect`]: {
        root: true,
        handler({ commit }) {
          logger.info('disconnect')
          commit('socketConnected', false)
        }
      },
      [`${socketActionNamePrefix}connect`]: {
        root: true,
        handler({ dispatch }, event) {
          logger.info('connect', event)
          return dispatch(`${socketActionNamePrefix}reconnect`, event, { root: true })
        }
      },
      [`${socketActionNamePrefix}reconnect`]: {
        root: true,
        handler({ commit, dispatch, state }) {
          const socket = getSocket(state.component)

          logger.info('reconnect', socket.id)
          commit('socketId', socket.id)
          commit('socketConnected', true)
          socket.emit('authentication', {
            time: Date.now(),
            user: 'kawa-user',
            component: state.component,
          })
          dispatch('requestCurrentUsers')
        }
      }
    },
  }
}
