import Actions from '../actions'
import Logger from '../../utilities/log'
import formatString from '../../../format/string'
import { updateComments } from '../../comments/actions'
import { assignCampaignHistory } from '../assignCampaignHistory'
import { assignRowDetails } from '../assignRowDetails'
import { compareAndReassign } from '../../utilities/compareAndReassign'
import { getDefaultFields } from '../fields'
import { getDefaultSections } from '../../campaigns/getDefaultSections'
import { moveArrayElement } from '../../utilities/moveArrayElement'
import {
  defaultSortBy as DEFAULT_SORT_BY,
  defaultSortDirection as DEFAULT_SORT_DIRECTION,
  sortCalculatorDefinitions as SORT_CONSTANTS,
} from '../sortCalculatorDefinitions'
import { updateRequestChannel } from '../../../campaigns/constants'
import { getRowUpdatePayload } from './getRowUpdatePayload'
import timeLocal from '../../../format/timeLocal'

const logger = Logger('SheetStore')
const defaultViewItem = { name: 'Default', key: 'default' }
const minSheetUpdateVelocity = 5
const maxSheetUpdateVelocity = 90
const desiredSheetFullUpdateDelay = (10 * 60 * 1000)

const BIG_NUM = 1000000000
const WORK_SESSION = {
  minAssignedCampaigns: 3,
  optimAssignedCampaigns: 10,
  offerCampaignsCount: 20
}

export const Sheet = {
  state: {
    available: [],
    availableSheetsLoaded: false,
    currentUser: {},
    currentUserImpact: {},
    gridReference: null,
    idsQueuedToUpdate: [],
    idsQueueTimeout: null,
    lastClickedRowId: null,
    lastClickedCell: null,
    lastNotificationAt: 0,
    loadedSheet: {},
    notifications: [],
    partialsLoadingFor: null,
    partialsLoadedFor: null,
    selectedRowIds: [],
    selectedSheetId: null,
    selectedSheetLoading: false,
    selectedSheetTableMode: 'light',
    selectedSheetViewKey: 'default',
    selectedSheetViewLoaded: false,
    selectedSheetViewUpdating: false,
    sheetRows: [],
    sheetQuickSearchInput: '',
    sheetConfiguration: {
      fields: getDefaultFields(),
      sections: getDefaultSections(),
      loadedAt: 0
    },
    sheetCurrentPage: 0,
    sheetDataVersion: 0,
    sheetRowLimit: 200,
    sheetRowLastUpdatedTime: 0,
    sheetRowsLastUpdatedTime: 0,
    sheetRowLastUpdatedCampaignId: null,
    sheetRowLastUpdatedUserId: null,
    sheetUpdateDelay: 60000,
    sheetUpdateVersion: 0,
    sheetUpdatingCampaigns: [],
    sheetViews: [
      defaultViewItem
    ],
    sheetViewsLoaded: false,
    sortBy: DEFAULT_SORT_BY,
    sortDirection: DEFAULT_SORT_DIRECTION,
    viewFilters: {},
  },
  actions: {
    markRowsUpdating({ commit }, { campaignIds, value = true }) {
      campaignIds.forEach(campaignId => {
        commit('updateCampaignRow', {
          campaignId,
          payload: {
            rowUpdating: value
          }
        })
      })
    },
    notify({ commit }, { message, type = 'info', hideAfter = 600 }) {
      return commit('notification', { message, type, hideAfter })
    },
    async switchCampaignSubType(_context, { campaignId, newSubType }) {
      const { data } = await Actions.switchCampaignSubType({
        campaignId,
        newSubType,
      })
      return data
    },
    async removeCampaignNote(_context, { campaignId, noteId }) {
      await Actions.removeCampaignNote({
        campaignId,
        noteId
      })
    },
    async triggerAttention({
      dispatch,
      rootState,
      commit,
    }, {
      campaign,
      campaigns = [campaign],
      forAll = false,
      period,
    }) {
      const actions = []
      let hideWorkSession = false

      campaigns.forEach(campaign => {
        const { id: campaignId } = campaign
        const {
          sifiId: userId,
          email: userEmail,
        } = rootState.currentUser
        const payload = {
          id: campaignId,
          rowUpdatedUserId: userId,
        }
        const ignores = [...campaign.ignores]
        const ignoredBy = [...campaign.ignoredBy]

        if (campaign.ignoredByMe) {
          const myIgnoreIdx = ignores.findIndex(({
            userEmail: email
          }) => (userEmail === email))

          hideWorkSession = true
          ignores.splice(myIgnoreIdx, 1)
          ignoredBy.splice(myIgnoreIdx, 1)

          payload.ignores = ignores
          payload.ignoredByMe = false
          payload.ignoredBy = ignoredBy
          actions.push({
            campaignId,
            name: 'acknowledge',
          })
        } else {
          payload.ignoredByMe = true
          if (forAll) {
            payload.ignoredByMeAndOthers = true
            payload.ignoredByAll = true
            payload.ignoredBy = [...ignoredBy, 'all']
            payload.ignores = [
              ...ignores, {
                userEmail: 'all',
                time: Date.now(),
                until: Date.now(),
              }
            ]
          } else {
            payload.ignoredBy = [...ignoredBy, userEmail]
            payload.ignores = [
              ...ignores, {
                userEmail,
                time: Date.now(),
                until: Date.now(),
              }
            ]
          }
          actions.push({
            campaignId,
            name: 'ignore',
            parameters: { period, forAll },
          })
        }
        commit('updateSheetRow', {
          payload,
          userEmail,
          userId,
        })
      })
      const { data: results } = await Actions.actionCampaigns(actions)

      if (hideWorkSession) {
        dispatch('workSession/confirm', false, { root: true })
      }
      return results
    },
    async triggerActions({ commit, dispatch, rootState }, { clear = false, rows }) {
      const actions = []
      let hideWorkSession = false

      rows.forEach(row => {
        const {
          claimed,
          claimedByMe,
          id,
          sealed,
          sealedByMe,
        } = row
        const {
          sifiId: userId,
          email: userEmail,
        } = rootState.currentUser.sifiId
        const payload = {
          id,
          rowUpdatedUserId: userId,
        }
        let actionName

        if (sealedByMe || (clear && claimedByMe)) {
          actionName = clear ? 'clear' : 'unseal'
          payload.sealed = false
          payload.sealedBy = null
          payload.sealedByMe = false
          payload.claimedBy = null
          payload.claimedByMe = false
          payload.claimed = false
          hideWorkSession = true
        } else if (claimedByMe) {
          actionName = 'seal'
          payload.sealed = true
          payload.sealedBy = userEmail
          payload.sealedByMe = true
          hideWorkSession = true
        } else if (!claimed && !sealed) {
          actionName = 'claim'
          payload.claimed = true
          payload.claimedBy = userEmail
          payload.claimedByMe = true
        }
        if (actionName) {
          actions.push({
            name: actionName,
            campaignId: id,
          })
          commit('updateSheetRow', {
            payload,
            userEmail,
            userId,
          })
        }
      })
      const { data: results } = await Actions.actionCampaigns(actions)

      if (hideWorkSession) {
        dispatch('workSession/confirm', false, { root: true })
      }
      return results
    },
    async updateAdopsAssignment({ commit }, {
      campaignIds,
      userId,
    }) {
      const { data: { success } } = await Actions.updateAdopsAssignment({
        campaignIds,
        userId,
      })

      return success
    },
    async clearComments({ commit }, campaignIds) {
      for (const campaignId of campaignIds) {
        commit('updateCampaignRow', {
          campaignId,
          payload: {
            commentCount: 0,
            comments: [],
          }
        })
      }
      const { data: { success } } = await updateComments({
        campaignIds,
        comments: '',
      })
      return success
    },
    async addViewFilter({ state, commit }, { field, values }) {
      commit('viewFilter', { field, values })
      commit('version', 1)
      await Actions.addViewFilter({
        field,
        sheetId: state.selectedSheetId,
        values,
        viewKey: state.selectedSheetViewKey,
      })
    },
    handleCampaignUpdate({
      commit,
      dispatch,
      getters,
      rootState: {
        currentUser: {
          email: userEmail,
          sifiId: userId,
        }
      }
    }, {
      campaignId,
      event,
      group,
      ...payload
    }) {
      const row = getters.sheetItemOf(campaignId)
      const rowUpdatePayload = getRowUpdatePayload({
        event,
        group,
        payload,
        row,
        userEmail,
      })

      logger.info('handleCampaignUpdate', {
        rowUpdatePayload, payload, userEmail, row
      })
      if (rowUpdatePayload) {
        commit('updateSheetRow', {
          campaignId,
          payload: {
            id: campaignId,
            ...rowUpdatePayload,
          },
          userEmail,
          userId,
        })
      } else {
        dispatch('markRowsUpdating', {
          campaignIds: [campaignId]
        })
      }
    },
    removeViewFilters({ commit, state }, field) {
      commit('viewFilter', {
        field,
        values: []
      })
      commit('version', 1)
      commit('updateQuickSearchInput', '')
      return Actions.removeViewFilters({
        field: (typeof field === 'string' ? field : 'ALL'),
        sheetId: state.selectedSheetId,
        viewKey: state.selectedSheetViewKey
      })
    },
    async checkCampaignUpdates({ dispatch, state, getters }) {
      const now = Date.now()
      const rowsCopy = [...state.sheetRows]
        .sort((a, b) => (a.refreshedAt - b.refreshedAt))
      const campaignsToRefresh = rowsCopy
        .filter(row => (
          row.refreshedAt < (now - state.sheetUpdateDelay) &&
          !row.rowUpdateRequested
        ))
        .slice(0, getters.sheetUpdateVelocity)
      const campaignIdsToRefresh = campaignsToRefresh
        .map(({ id }) => id)
      if (!campaignIdsToRefresh.length) return 0
      logger.info('checkCampaignUpdates', campaignIdsToRefresh)
      await dispatch('requestCampaignUpdates', campaignIdsToRefresh)

      return campaignIdsToRefresh.length
    },
    requestCampaignUpdates({ dispatch, commit }, campaignIds) {
      campaignIds.forEach(campaignId => {
        commit('updateCampaignRow', {
          campaignId,
          payload: {
            rowUpdateRequested: true
          }
        })
      })
      return dispatch('socket/send', {
        campaignIds,
        channel: updateRequestChannel,
      }, { root: true })
    },
    async getCampaignUpdates({
      commit,
      dispatch,
      getters,
      rootState,
    }, {
      campaignIds,
      userId = rootState.currentUser.sifiId,
      forceLoad
    }) {
      if (campaignIds.length === 0) {
        return false
      } else {
        logger.info('getCampaignUpdates', campaignIds.length)
        commit('unqueueCampaignIds', campaignIds)
        dispatch('markRowsUpdating', { campaignIds })

        const {
          data: {
            campaigns,
          }
        } = await Actions.getSheetCampaignRows({
          campaignIds,
          forceLoad,
          sheetId: getters.selectedSheetId,
        })

        campaigns.forEach(campaign => {
          commit('updateSheetRow', {
            payload: {
              ...campaign,
              rowUpdatedUserId: userId,
            },
            userEmail: rootState.currentUser.email,
            userId: rootState.currentUser.sifiId,
          })
        })
        dispatch('notify', {
          message: `${campaigns.length} rows got updates.`,
          type: `success`,
          hideAfter: 2
        })
        dispatch('getCampaignPartials', { campaignIds, forceLoad })
        return campaignIds.length
      }
    },
    [`socket:sheet_campaignUpdateNotification`]: {
      root: true,
      handler({ dispatch, state }, { campaignIds, category, userEmail }) {
        for (const campaignId of campaignIds) {
          const rowIdxInSheet = state.sheetRows.findIndex(
            c => c.id === campaignId
          )
          if (rowIdxInSheet >= 0) {
            logger.info(
              `campaignUpdateNotification [${campaignId}]`
            )
            dispatch('queueCampaignUpdates', [campaignId])
            dispatch('notify', {
              message: (
                `[${campaignId}] ` +
                `${formatString.standardiseString(category)} by ${userEmail}`
              ),
              type: 'default'
            })
          }
        }
      }
    },
    [`socket:sheet_campaignUpdateData`]: {
      root: true,
      handler({ commit, dispatch, rootState }, payload) {
        if (payload.length) {
          logger.info(
            `socket:sheet_campaignUpdateData [${payload.length}]`
          )
          payload.forEach(row => commit('updateSheetRow', {
            payload: row,
            userEmail: rootState.currentUser.email,
            userId: rootState.currentUser.sifiId,
          }))
          return dispatch('notify', {
            message: `Got campaign updates [${payload.length}]`,
            type: `success`,
            hideAfter: 2
          })
        } else {
          logger.error('Campaigns not received...', payload)
        }
      },
    },
    async forceSheetExtraction({ dispatch, getters }, sheetId) {
      await Actions.forceExtraction(sheetId)
      await dispatch('getAvailableSheets')
      return dispatch('mountSheet', getters.selectedSheetPath)
    },
    validateSheetExtraction(_context, sheetOptions) {
      return Actions.validateExtraction(sheetOptions)
        .then(({ data: { count } }) => count)
    },
    async submitSheet({ dispatch }, options) {
      await Actions.submitSheet(options)
      return dispatch('getAvailableSheets')
    },
    selectSheetRows({ commit, state }, ids) {
      commit('setSelectedRowIds', ids)
      state.sheetRows.forEach(({ id, isSelected }) => {
        const isIncluded = ids.includes(id)
        const toSelect = (isIncluded && !isSelected)
        const toUnselect = (!isIncluded && isSelected)

        if (toSelect || toUnselect) {
          commit('updateCampaignRow', {
            campaignId: id,
            payload: {
              isSelected: toSelect
            }
          })
        }
      })
      // commit('dataVersion', 1)
    },
    unselectSheetRows({ state, commit }) {
      state.gridReference.grid.getSelectionModel().setSelectedRanges([])
      state.sheetRows
        .filter(({ isSelected }) => isSelected)
        .forEach(id => {
          commit('updateCampaignRow', {
            campaignId: id,
            payload: { isSelected: false }
          })
        })
      commit('dataVersion', 1)
    },
    async updateSheet({ dispatch }, options) {
      await Actions.updateSheet(options)
      dispatch('getAvailableSheets')
    },
    async getAvailableSheets({ commit }) {
      const { data: { available } } = await Actions.getAvailable()

      commit('available', available)
    },
    async applySheetView({
      commit,
      dispatch,
      state,
    }, viewKey = 'default') {
      const { selectedSheetViewKey, sheetConfiguration } = state
      const alreadyLoaded = (
        selectedSheetViewKey === viewKey &&
        sheetConfiguration.loadedAt > 0
      )
      logger.info('applySheetView', {
        alreadyLoaded,
        viewKey
      })
      if (!alreadyLoaded) {
        const {
          data: { view }
        } = await Actions.getViewAttributes(viewKey)
        logger.info('applySheetView', view)
        if (view && view.key === viewKey) {
          commit('view', { ...view, source: 'getViewAttributes' })
        } else {
          commit('view', { ...view, source: 'applySheetView:noViewAttributes' })
          dispatch('notify', {
            type: 'warning',
            message: (
              `Unable to load selected view [${viewKey}], ` +
              `switching to default.`
            )
          })
        }
      }
      dispatch('getViewFilters')
    },
    async getViewFilters({ commit, state }) {
      const { data: { filters } } = await Actions.getViewFilters({
        sheetId: state.selectedSheetId,
        viewKey: state.selectedSheetViewKey
      })
      logger.info('getViewFilters', filters)
      commit('viewFilters', filters)
      return filters
    },
    queueCampaignUpdates({ dispatch, state, commit }, campaignIds = []) {
      commit('queueCampaignIds', campaignIds)
      commit('idsQueueTimeout', setTimeout(() => {
        dispatch('getCampaignUpdates', {
          campaignIds: state.idsQueuedToUpdate,
        })
      }, 5000))
    },
    reorderField({ dispatch, state, commit, getters }, { field, vector }) {
      const curPosition = state.sheetConfiguration.fields.findIndex(
        f => f.key === field.key
      )
      const nextVisibleFieldIndexAtVector = (
        getters.visibleSheetFields.findIndex(f => f.key === field.key) + vector
      )
      if (nextVisibleFieldIndexAtVector >= 0) {
        const targetField = getters.visibleSheetFields[nextVisibleFieldIndexAtVector]
        const targetFieldPos = state.sheetConfiguration.fields.findIndex(
          f => f.key === targetField.key
        )

        if (
          targetFieldPos >= 0 &&
          targetFieldPos < state.sheetConfiguration.fields.length
        ) {
          const reorderedFields = moveArrayElement(
            state.sheetConfiguration.fields,
            curPosition,
            targetFieldPos
          )

          commit('view', {
            ...getters.selectedSheetViewAttributes,
            fields: reorderedFields,
            source: 'reorderField'
          })

          return dispatch('updateSelectedSheetView')
        }
      }
    },
    updateSelectedSheetView({ dispatch, getters, commit }) {
      const view = getters.selectedSheetView

      commit('version', 1)
      return dispatch('updateSheetView', {
        name: view.name,
        key: view.key
      })
    },
    reorderDetailSection({ dispatch, commit, state, getters }, {
      currentPosition,
      targetPosition
    }) {
      const reorderedSections = moveArrayElement(
        state.sheetConfiguration.sections,
        currentPosition,
        targetPosition
      )

      commit('view', {
        ...getters.selectedSheetViewAttributes,
        sections: reorderedSections,
        source: 'reorderSections'
      })

      return dispatch('updateSelectedSheetView')
    },
    async updateSheetView({
      dispatch,
      getters,
      state,
    }, attributes = {}) {
      await Actions.addView({
        ...attributes,
        mode: state.selectedSheetTableMode,
        fields: getters.recordFields,
        sections: getters.recordSections,
        sortBy: state.sortBy,
        sortDirection: state.sortDirection,
      })
      return dispatch('getSheetViews')
    },
    async removeSheetView({ dispatch }, viewKey) {
      await Actions.removeView(viewKey)
      await dispatch('getSheetViews')
    },
    async getSheetViews({ commit, dispatch, state }) {
      const { data } = await Actions.getViews()
      commit('views', data)
      commit('invalidateViewConfig')
      logger.warn('getSheetViews', data, state.selectedSheetViewKey)
      await dispatch('applySheetView', state.selectedSheetViewKey)
    },
    async removeSheet({ dispatch, state }, sheetId) {
      await Actions.removeSheet(sheetId)
      await dispatch('getAvailableSheets')
      if (state.available.length > 0) {
        return dispatch('mountSheet', state.available[0].path)
      }
    },
    async getCampaignPartials({ commit, state }, { campaignIds, forceLoad }) {
      const { data: { partials } } = await Actions.getCampaignPartials({
        sheetId: state.selectedSheetId,
        campaignIds,
        forceLoad,
      })

      partials.filter(
        ({ id }) => state.sheetRows.find(r => r.id === id)
      ).forEach(partial => commit('updateCampaignRow', {
        campaignId: partial.id,
        payload: partial,
      }))
    },
    async getSheetPartials({ commit, state }) {
      commit('partialsLoading', true)
      const { data: { partials } } = await Actions.getSheetPartials(state.selectedSheetId)
      partials.forEach(
        row => commit('updateCampaignRow', {
          campaignId: row.id, payload: row
        })
      )
      commit('partialsLoading', false)
      return partials
    },
    async getSheetRows({
      commit,
      dispatch,
      state,
      rootState,
    }) {
      const sheetId = state.selectedSheetId
      const sheetProps = state.available.find(({ id }) => id === sheetId)

      commit('sheetIsLoading', true)
      commit('loadedSheet', sheetProps)

      const { data } = await Actions.getSheetRows(sheetId)
      const {
        campaignReminders = [],
        email: userEmail,
        sifiId: userId,
      } = rootState.currentUser
      const rows = (
        data.rows instanceof Array
          ? data.rows.map(
            campaign => assignRowDetails({
              row: assignCampaignHistory({
                campaign,
                userEmail,
                today: data.today
              }),
              reminder: campaignReminders.find(
                ({ campaignId }) => (
                  campaign.id === campaignId
                )
              ),
              selectedIds: state.selectedRowIds,
              userEmail,
              userId,
            })
          )
          : []
      )
      commit('today', data.today)
      commit('updateSheetRows', rows)
      commit('sheetIsLoading', false)
      commit('sheetRowsRefresh', 'getSheetRows')
      dispatch('socket/requestCurrentUsers', null, { root: true })

      await dispatch('applySheetView', state.selectedSheetViewKey)
      await dispatch('workSession/getDetails', null, { root: true })
      await dispatch('getSheetPartials')

      return dispatch('notify', {
        message: `'${sheetProps.name}' loaded.`,
        type: 'success'
      })
    },
    triggerSheetVersionUpdate({ commit }, vector = 1) {
      return commit('version', vector)
    },
    triggerSheetDataVersionUpdate({ commit }, vector = 1) {
      return commit('dataVersion', vector)
    },
    async updateSorting({ commit, dispatch }, { by, direction }) {
      commit('sorting', { by, direction })
      await dispatch('updateSelectedSheetView')
      commit('sheetRowsRefresh', 'updateSorting')
    },
    selectSheet({ commit }, sheetId) {
      commit('selectedSheet', sheetId)
      commit('version', 0)
    },
    reloadCurrentSheet({ dispatch, getters }) {
      return dispatch('mountSheet', getters.selectedSheetPath)
    },
    async mountSheet({ commit, dispatch, getters }, sheetPath) {
      const routedSheet = getters.availableSheetByPath(sheetPath)

      if (routedSheet) {
        commit('selectedSheet', routedSheet.id)
        await dispatch('currentUser/getMyReminders', null, { root: true })
        await dispatch('currentUser/getTrackedCampaigns', null, { root: true })
        await dispatch('getSheetRows')
      } else {
        commit('selectedSheet', null)
      }
    },
  },
  getters: {
    currentSheetIsMyCampaigns: ({ selectedSheetId }) => (selectedSheetId === 0),
    currentUserId: (_s, _g, rootState) => rootState.currentUser.sifiId,
    getAssignables: (
      _state, {
        currentUserAssignedRows,
        rowsToOffer
      }
    ) => () => {
      const hasLessThanMin = (
        currentUserAssignedRows.length <= WORK_SESSION.minAssignedCampaigns
      )
      const available = rowsToOffer()
      return {
        hasLessThanMin,
        toAutoAssign: available.slice(0, WORK_SESSION.minAssignedCampaigns),
        availableCount: available.length,
      }
    },
    gridData: state => state.gridReference.slickGrid.getData(),
    rowsToOffer: (state, _getters, rootState) => () => {
      const toOffer = state.sheetRows
        .filter(({
          claimed,
          sealed,
          ignores,
        }) => (
          !claimed &&
          !sealed &&
          ignores.length === 0
        ))
      const countTouchesByMe = c => c.trackedEdits.filter(({ userEmail }) => (
        userEmail === rootState.currentUser.email
      )).length

      toOffer.sort(
        (a, b) => (a.adops_assignment_id > 0) - (b.adops_assignment_id > 0)
      )

      if (rootState['workSession/preferencePrioritizeAssigned']) {
        toOffer.sort((a, b) => b.isMyAssignment - a.isMyAssignment)
      }

      if (rootState['workSession/preferencePrioritizeTouched']) {
        toOffer.sort((a, b) => (
          countTouchesByMe(b) - countTouchesByMe(a)
        ))
      }

      return toOffer.slice(0, WORK_SESSION.offerCampaignsCount)
    },
    sheetColumnNames(_s, { sheetFields }) {
      return sheetFields
        .filter(({ visible }) => visible)
        .map(({ key }) => key)
    },
    getSheetColumnOptions: (state, { sheetFields }) => (assignmentMode = false) => {
      return sheetFields.map((f) => {
        const visible = (
          f.visible && (
            !f.hideInAssignment || assignmentMode === false
          )
        ) || (
          ['actions', 'attention'].includes(f.key) && assignmentMode
        )

        const filterValues = state.viewFilters[f.key]

        return {
          ...f,
          filterValues,
          filterable: !!f.filterEnabled,
          partialFieldLoaded: (
            !f.isPartial ||
            state.partialsLoadedFor === state.selectedSheetId
          ),
          visible,
        }
      })
    },
    sheetExtractedAt: (_state, { selectedSheet }) =>
      selectedSheet?.lastRunAt
        ? timeLocal(selectedSheet.lastRunAt)
        : null,
    sheetQuickSearchActive: ({
      sheetQuickSearchInput,
      sheetRows
    }, { displayedSheetRows }) => {
      const quickSearchInputApplied = (
        sheetQuickSearchInput &&
        sheetQuickSearchInput.length > 0 &&
        sheetRows.length !== displayedSheetRows.length
      )

      return quickSearchInputApplied
    },
    sheetViewFilters: ({ viewFilters }, { visibleFieldOf }) =>
      Object.keys(viewFilters).reduce((filters, key) => {
        if (visibleFieldOf(key) && viewFilters[key].length) {
          return {
            ...filters,
            [key]: viewFilters[key],
          }
        }
        return filters
      }, {}),
    sheetViewFiltersApplied: ({
      viewFilters
    }, { sheetFields }) => sheetFields.some(({ key, visible }) => (
      visible &&
      viewFilters[key] instanceof Array &&
      viewFilters[key].length > 0
    )),
    sheetRowsFiltersActive: (_state, {
      sheetViewFiltersApplied,
      sheetQuickSearchActive
    }) => (
      sheetViewFiltersApplied ||
      sheetQuickSearchActive
    ),
    sheetUpdateVelocity: state => {
      const delay = state.sheetUpdateDelay
      const rowLen = state.sheetRows.length
      const divider = (desiredSheetFullUpdateDelay / delay)
      const result = parseInt(rowLen / divider)

      return Math.min(
        Math.max(minSheetUpdateVelocity, result), maxSheetUpdateVelocity
      )
    },
    selectedSheetView: state => state.sheetViews.find(
      v => v.key === state.selectedSheetViewKey
    ),
    sheetItemOf: state => campaignId => state.sheetRows.find(
      i => i.id === campaignId
    ),
    viewFilterValuesFor: state => field => {
      return (
        state.viewFilters[field] &&
        state.viewFilters[field].length
      )
        ? state.viewFilters[field]
        : []
    },
    selectedSheetViewAttributes: (state, { sheetFields }, rootState) => ({
      key: state.selectedSheetViewKey,
      email: rootState.currentUser.email,
      fields: sheetFields,
      sections: state.sheetConfiguration.sections,
      mode: state.selectedSheetTableMode
    }),
    selectedSheetViewName: state => {
      const selectedSheetView = state.sheetViews.find(
        v => v.key === state.selectedSheetViewKey
      )

      return selectedSheetView.name
    },
    availableSheets: state => state.available,
    selectedSheetStats: (state, _getters, rootState) => {
      const stats = {
        myImpactedRows: [],
        potentialSpend: 0,
        projectedSpend: 0,
        leftoverYesterday: 0,
        myImpactRelative: 0,
        myImpactAbsolute: 0
      }

      state.sheetRows.forEach(row => {
        const {
          potential_spend,
          spend_projection_dollars,
          potential_spend_left_y,
          impact,
          claimedBy,
          sealedBy
        } = row

        stats.potentialSpend += potential_spend
        stats.projectedSpend += spend_projection_dollars
        stats.leftoverYesterday += potential_spend_left_y

        if (
          impact &&
          impact.defined &&
          [claimedBy, sealedBy].includes(rootState.currentUser.email)
        ) {
          stats.myImpactRelative += (impact.relative * 100)
          stats.myImpactAbsolute += (impact.absolute)
          stats.myImpactedRows.push(row)
        }
      })

      stats.projectedOfPotentialPerc = parseInt(
        stats.projectedSpend / stats.potentialSpend * 100
      )
      stats.leftOfPotentialYesterday = parseInt(
        stats.leftoverYesterday / stats.potentialSpend * 100
      )

      return stats
    },
    selectedSheetRowByCampaignId: state => campaignId => state.sheetRows.find(
      r => r.id === campaignId
    ),
    selectedSheetCampaignIds: state => state.sheetRows.map(
      ({ id }) => id
    ),
    selectedSheetId: state => state.selectedSheetId,
    sheetTableModeClass: state => (
      `sheet-table-mode-light`
    ),
    availableSheetByPath: state => (sheetPath) => state.available.find(
      s => (s.path === sheetPath)
    ),
    selectedSheetLoading: state => state.selectedSheetLoading,
    selectedSheetLoaded: state => (
      !state.selectedSheetLoading && state.sheetRows.length > 0
    ),
    selectedSheet: state => state.available.find(
      ({ id }) => state.selectedSheetId === id
    ),
    selectedSheetHasFormula: state => state.selectedSheetId > 0,
    selectedSheetSetsValid: ({
      selectedSheet,
      selectedSheetHasFormula
    }) => (
      selectedSheetHasFormula &&
      selectedSheet.sets.length &&
      selectedSheet.sets.every(record => record.setLoaded)
    ),
    selectedSheetPath: (_s, getters) => {
      const selectedSheet = getters.selectedSheet

      if (selectedSheet) {
        return selectedSheet.path
      } else return null
    },
    sheetRowIsUpdating: state => campaignId => state.sheetUpdatingCampaigns.includes(campaignId),
    visibleFieldOf: (_s, { visibleSheetFields }) => key => visibleSheetFields.find(f => f.key === key),
    visibleSheetFields: (_s, { sheetFields }) => sheetFields.filter(
      ({ visible }) => visible
    ),
    sheetFields: (state, _g, rootState) => state.sheetConfiguration.fields
      .filter(
        f => (!f.limitedFeature || !rootState.currentUser.limitFeatures)
      ),
    recordFields: state => state.sheetConfiguration.fields.map(({
      key,
      visible,
      width
    }) => ({
      key,
      visible,
      width,
    })),
    sheetSections: state => state.sheetConfiguration.sections,
    recordSections: state => state.sheetConfiguration.sections.map(({
      key,
      visible
    }) => ({
      key,
      visible
    })),
    currentLoadedSheet: state => state.loadedSheet,
    updatesInProgress: state => (state.sheetUpdatingCampaigns.length),
    currentUserAssignedRows: state => (
      state.sheetRows.filter(({ claimedByMe }) => claimedByMe)
    ),
    selectedRows: state => state.sheetRows.filter(
      ({ isSelected }) => isSelected
    ),
    displayedSheetRows({ sheetQuickSearchInput, sheetRows }) {
      if (sheetQuickSearchInput && sheetQuickSearchInput.length > 1) {
        return sheetRows.filter(
          row => (new RegExp(sheetQuickSearchInput, 'gi'))
            .test(row.quickSearchString)
        )
      }
      return sheetRows
    },
    sortDefinition: ({ sortBy }) => (
      SORT_CONSTANTS[sortBy] ||
      SORT_CONSTANTS[DEFAULT_SORT_BY]
    ),
    sortRankCalculator: ({ sortDirection }, { sortDefinition }) => (
      sortDefinition.rank(sortDirection)
    ),
    sortCalculators: ({ sortBy }) => Object.keys(SORT_CONSTANTS).map(name => ({
      name,
      ...SORT_CONSTANTS[name],
      selected: (sortBy === name),
    })),
    sortDirectionLabel: ({ sortDirection }) => (
      sortDirection > 0 ? 'Descending' : 'Ascending'
    ),
  },
  mutations: {
    idsQueueTimeout(state, timeout) {
      if (state.idsQueueTimeout) {
        clearTimeout(state.idsQueueTimeout)
      }
      state.idsQueueTimeout = timeout
    },
    invalidateViewConfig(state) {
      state.sheetConfiguration.loadedAt = 0
    },
    partialsLoading(state, loading) {
      state.partialsLoadingFor = loading ? state.selectedSheetId : null
      state.partialsLoadedFor = !loading ? state.selectedSheetId : null
    },
    queueCampaignIds(state, payload) {
      if (payload instanceof Array) {
        payload.forEach(campaignId => {
          if (!state.idsQueuedToUpdate.includes(campaignId)) {
            state.idsQueuedToUpdate.push(campaignId)
          }
        })
      }
    },
    setSelectedRowIds(state, ids) {
      state.selectedRowIds = [...ids]
    },
    unqueueCampaignIds(state, payload) {
      if (payload instanceof Array) {
        const queue = [...state.idsQueuedToUpdate]
        payload.forEach(campaignId => {
          const idx = queue.findIndex(id => campaignId === id)
          if (idx >= 0) {
            queue.splice(idx, 1)
          }
        })
        state.idsQueuedToUpdate = queue
      } else {
        state.idsQueuedToUpdate = []
      }
    },
    updateQuickSearchInput(state, str) {
      state.sheetQuickSearchInput = str
    },
    notification(state, payload) {
      const now = Date.now()

      state.notifications = state.notifications.filter(
        ({ at, hideAfter = 0 }) => (at + (hideAfter * 1000)) > now
      )
      state.notifications.push({
        ...payload,
        at: now
      })
      state.lastNotificationAt = now
    },
    version(state, vector) {
      if (vector > 0) {
        state.sheetUpdateVersion += vector
      } else {
        state.sheetUpdateVersion = 0
      }
    },
    updateLastClicked(state, { rowId, cell }) {
      state.lastClickedCell = cell
      state.lastClickedRowId = rowId
    },
    dataVersion(state, vector) {
      if (vector > 0) {
        state.sheetDataVersion += vector
      } else {
        state.sheetDataVersion = 0
      }
    },
    viewFilters(state, payload = {}) {
      const newViewFilters = {}

      state.sheetConfiguration.fields.forEach(field => {
        if (payload[field.key]) {
          const conversionDefined = (typeof field.filterConversion === 'function')
          newViewFilters[field.key] = (
            conversionDefined
              ? payload[field.key].map(val => field.filterConversion(val))
              : payload[field.key]
          ).map(v => `${v}`)
        } else {
          newViewFilters[field.key] = []
        }
      })
      state.viewFilters = newViewFilters
    },
    viewFilter(state, { field, values }) {
      const {
        [field]: _formerValues,
        ...filters
      } = state.viewFilters
      if (values.length) {
        filters[field] = values
      }
      state.viewFilters = filters
    },
    updateCampaignRow(state, { campaignId, payload }) {
      const rowIdx = state.sheetRows.findIndex(c => c.id === campaignId)
      if (rowIdx >= 0) {
        state.sheetRows.splice(rowIdx, 1, {
          ...state.sheetRows[rowIdx],
          ...payload
        })
      }
    },
    updateGridReference(state, reference) {
      state.gridReference = reference
    },
    loadedSheet(state, payload) {
      state.loadedSheet = payload
    },
    selectedSheet(state, payload) {
      state.selectedSheetId = payload
    },
    view(state, payload) {
      logger.warn('mutations:view', payload.key, payload.source)
      const viewDef = state.sheetViews.find(v => v.key === payload.key)
      const sortByIncoming = (
        typeof SORT_CONSTANTS[payload.sortBy] === 'object'
      )
      const sortDirectionIncoming = [1, -1].includes(
        payload.sortDirection
      )

      let fields = getDefaultFields()
      let sections = getDefaultSections()
      let loadedAt

      state.selectedSheetViewKey = defaultViewItem.key

      state.sortDirection = (
        sortDirectionIncoming
          ? payload.sortDirection
          : DEFAULT_SORT_DIRECTION
      )
      state.sortBy = (
        sortByIncoming ? payload.sortBy : DEFAULT_SORT_BY
      )

      if (viewDef && !['undefined'].includes(payload.key)) {
        loadedAt = Date.now()
        state.sheetUpdateVersion++
        state.selectedSheetViewKey = payload.key
        state.selectedSheetTableMode = payload.mode

        if (payload.fields && payload.fields.length) {
          fields = compareAndReassign(
            payload.fields, fields, ['sortable']
          )
        }
        if (payload.sections && payload.sections.length) {
          sections = compareAndReassign(
            payload.sections, sections
          )
        }
      } else {
        logger.error('No view definition!')
      }

      state.sheetConfiguration = {
        ...state.sheetConfiguration,
        fields,
        sections,
        loadedAt,
      }
      logger.info('mutation:view', fields)
    },
    available(state, payload = []) {
      state.available = payload
      state.availableSheetsLoaded = true
    },
    sheetIsLoading(state, payload) {
      state.selectedSheetLoading = payload
    },
    updateSheetRows(state, payload) {
      logger.info('updateSheetRows', payload)
      state.sheetRows = payload
    },
    updateSheetRow(state, { userEmail, userId, payload }) {
      const rowIdx = state.sheetRows.findIndex(
        c => c.id === payload.id
      )
      if (rowIdx >= 0) {
        logger.info('updateSheetRow', {
          rowIdx,
          row: state.sheetRows[rowIdx]
        })
        const row = { ...state.sheetRows[rowIdx] }
        const updatedRow = assignRowDetails({
          row: assignCampaignHistory({
            campaign: {
              ...row,
              ...payload,
              rowUpdatedTime: state.sheetRowLastUpdatedTime,
              rowUpdating: false,
              rowUpdateRequested: false,
            },
            today: state.today,
            userEmail,
          }),
          selectedIds: state.selectedRowIds,
          userEmail,
          userId,
        })
        const updatingIdx = state.sheetUpdatingCampaigns.indexOf(payload.id)
        logger.info('updatedRow', updatedRow)
        state.sheetRowLastUpdatedCampaignId = payload.id
        state.sheetRowLastUpdatedUserId = payload.rowUpdatedUserId
        state.sheetRowLastUpdatedTime = Date.now()
        state.sheetRows.splice(rowIdx, 1, updatedRow)
        if (updatingIdx >= 0) {
          state.sheetUpdatingCampaigns.splice(updatingIdx, 1)
        }
      }
    },
    sheetRowsRefresh(state, source) {
      logger.info('sheetRowsRefresh', source)
      const sortRankCalculatorDefinition = (
        SORT_CONSTANTS[state.sortBy] ||
        SORT_CONSTANTS[DEFAULT_SORT_BY]
      )
      const sortRankCalculator = sortRankCalculatorDefinition.rank(
        state.sortDirection
      )

      state.sheetRows = state.sheetRows.map(row => {
        const sortValPart = (
          sortRankCalculator(row) + (
            (
              (row.sealedByMe ? 1 : (row.sealed ? -1 : 0)) +
              (row.claimedByMe ? 2 : (row.claimed ? -2 : 0))
            ) * BIG_NUM
          )
        )
        const sortVal = (
          sortValPart - (row.ignoredByMe ? (3 * BIG_NUM) : 0)
        )

        return {
          ...row,
          sortVal,
        }
      }).sort((a, b) => (b.sortVal - a.sortVal))
      state.sheetRowsLastUpdatedTime = Date.now()
    },
    sorting(
      state, {
        by = state.sortBy,
        direction = state.sortDirection
      }
    ) {
      state.sortBy = by
      state.sortDirection = direction
    },
    today(state, day) {
      state.today = day
    },
    updateSheetTableMode(state, mode) {
      state.selectedSheetTableMode = (mode === 'dark' ? 'dark' : 'light')
      state.version += 1
    },
    views(state, payload) {
      state.sheetViews = [
        defaultViewItem,
        ...payload.filter(v => v.key !== 'default')
      ]
      state.sheetViewsLoaded = true
    },
  }
}
