import Actions from '../actions'
import { campaignIdIsValid } from '../campaignIdIsValid'
import { filterCampaignReportRows } from '../filterCampaignReportRows'
import Logger from '../../utilities/log'
import {
  getSpendDetails,
  getSpendProjections,
} from '../actions/spend'
import { kawaConstants } from '@/constants'
import { getCampaignAdvertiser } from '../actions/getCampaignAdvertiser'
import { addCampaignToRecents } from '../actions/recents'
import { standardiseString } from '../../../format/string'
import { determineAutoReportTypes } from '../determineAutoReportTypes'
const logger = Logger('Store:Campaign')

const reportGroupIsSelected = (type) =>
  typeof type === 'string' && type.includes('grouped_')
const getGroupReportTypes = ({
  campaignTypeId,
  campaignGoalType,
  reportType,
}) => {
  const { reportTypePerCampaignType } = kawaConstants.reporting
  if (reportType === 'details') return []
  if (reportType === 'auto') {
    logger.info('determining report types per campaign attributes')
    return determineAutoReportTypes({
      campaignTypeId,
      campaignGoalType,
    })
  }
  if (!reportGroupIsSelected(reportType)) return [reportType]
  const [, groupType] = reportType.split('grouped_')

  return (
    (reportTypePerCampaignType[campaignTypeId] ||
      reportTypePerCampaignType['1'])[groupType] || []
  )
}

const socketComponent = 'common'
export const Campaign = {
  state: {
    advertiserLoading: false,
    affiliatedCampaigns: [],
    edits: [],
    id: null,
    keywords: [],
    loading: false,
    notes: [],
    notesLoading: false,
    properties: {},
    propertiesLoadedAt: null,
    reports: [],
    spendDetails: null,
    spendDetailsLoading: false,
    spendProjections: null,
    spendProjectionsLoading: false,
    submittingReport: false,
  },
  getters: {
    advertiser: (state) => state.properties.advertiser,
    allReportsFinished: (state) =>
      state.reports.length && state.reports.every(({ finished }) => finished),
    campaignId: (state) => state.id,
    campaignIdIsValid: (state) => campaignIdIsValid(state.id),
    campaignProperties: (state) => state.properties,
    campaignReminder: (state, _g, { currentUser }) =>
      currentUser.campaignReminders.find(
        ({ campaignId }) => campaignId === state.id,
      ),
    campaignView: (state) => state.view,
    finishedReports: (_s, { sortedReports }) =>
      sortedReports.filter(({ finished }) => finished),
    hasMarginDetails: (state) => state.properties.hasMarginDetails,
    isLoading: (state) => (property) =>
      state[`${property}Loading`] || state.loading,
    marginDetails: (state) => state.properties.marginDetails,
    maxBid: (state) => state.properties.max_bid,
    propertiesLoaded: (state) =>
      state.properties.id === state.id && !state.properties.quick,
    propertiesQuickLoaded: (state) =>
      state.properties.id === state.id,
    propertiesLoading: (state) => state.loading,
    runningReports: (_s, { sortedReports }) =>
      sortedReports.filter(({ finished }) => !finished),
    sortedReports: ({ reports }) => reports.sort((a, b) => a.reportType - b.reportType),
    spendProjectionsRecorded: (state) =>
      state.spendProjections &&
      state.spendProjections.campaignId === state.id &&
      state.spendProjections.recorded,
    spendDetailsLoaded: (state) =>
      state.spendDetails &&
      state.spendDetails.campaignId === state.id,
    submittingReports: (state) =>
      state.reports.filter(({ finished, id }) => !finished && !id)
        .length > 0,
    today: ({ properties }) => properties.todayReportingDay,
  },
  mutations: {
    affiliatedCampaigns(state, payload) {
      if (payload instanceof Array) {
        state.affiliatedCampaigns = payload
      }
    },
    applyReportListerInput(
      state,
      { report: reportName, ...options },
    ) {
      const reportIdx = state.reports.findIndex(
        ({ name }) => name === reportName,
      )
      const report = state.reports[reportIdx]
      if (report) {
        const rows = filterCampaignReportRows(report, options)
        state.reports.splice(reportIdx, 1, {
          ...report,
          // dataVersion: report.dataVersion + 1,
          listerOptions: options,
          rows,
        })

        return rows
      }
      return []
    },
    cancelReport(state, reportId) {
      state.reports = [
        ...state.reports.filter(({ id }) => id !== reportId),
      ]
    },
    edits(state, payload = []) {
      state.edits = payload
    },
    id(state, value) {
      logger.info('mutation:id', value)
      if (campaignIdIsValid(value)) {
        state.id = value
      } else {
        state.id = null
      }
    },
    keywords(state, payload) {
      state.keywords = payload
    },
    loading(state, value = false) {
      state.loading = value
    },
    loadingProperty(state, { loading: value = false, property }) {
      state[`${property}Loading`] = !!value
    },
    properties(state, payload) {
      state.properties = payload
      state.propertiesLoadedAt = Date.now()
    },
    property(state, { attribute, payload }) {
      state.properties = {
        ...state.properties,
        [attribute]: payload,
      }
    },
    report(state, payload) {
      const reportIdxOfTheSameName = state.reports.findIndex(
        (r) => r.name === payload.name,
      )
      logger.info('mutation:report', payload, reportIdxOfTheSameName)
      if (reportIdxOfTheSameName > -1) {
        state.reports.splice(reportIdxOfTheSameName, 1)
      }
      state.reports = [...state.reports, payload]
    },
    reportItem(state, { itemName, itemId, payload }) {
      logger.info('reportItem', itemName, itemId, payload)
      const updatedReports = state.reports.map((report) => {
        const reportData = report.data || []
        const reportWithItem =
          report.finished &&
          report.name.indexOf(itemName) === 0 &&
          reportData.length > 0

        if (reportWithItem) {
          const dataItemIdx = reportData.findIndex(({ id }) => `${id}` === `${itemId}`)

          if (dataItemIdx >= 0) {
            reportData[dataItemIdx] = {
              ...reportData[dataItemIdx],
              ...payload,
            }
            return {
              ...report,
              data: [...reportData],
              // dataVersion: report.dataVersion + 1,
              rows: filterCampaignReportRows(report, report.listerOptions),
            }
          }
        } else {
          logger.warn(report.name, 'NOT FOUND', itemName, itemId)
        }
        return report
      })

      state.reports = [...updatedReports]
    },
    reportId(state, { autoDatesLabel, autoDatesReason, tempId, id }) {
      const idx = state.reports.findIndex(
        ({ tempId: temporaryId }) => tempId === temporaryId,
      )

      if (idx >= 0) {
        const report = {
          ...state.reports[idx],
          id,
          autoDatesLabel,
          autoDatesReason,
        }

        state.reports.splice(idx, 1)
        state.reports.unshift(report)
      } else {
        logger.warn(
          `mutation:reportId idx not found`,
          tempId,
          id,
          state.reports,
        )
      }
    },
    reportComplete(state, payload) {
      const {
        id,
        name,
        snapshotArrayed: rows,
        ...reportAttributes
      } = payload
      const idx = state.reports.findIndex(
        ({ id: reportId }) => reportId === id,
      )

      if (idx >= 0) {
        const report = {
          ...state.reports[idx],
          ...reportAttributes,
          data: rows,
          finished: true,
        }
        logger.info('reportComplete', {
          name,
          title: report.title,
          rows,
          payload,
        })
        state.reports.splice(idx, 1, report)
      } else {
        logger.warn(
          `mutation:reportComplete Unable to find queued report`,
          state.reports,
        )
      }
    },
    reportFailed(state, payload) {
      const { error = 'Unknown reporting error has ocurred.', id } =
        payload
      const idx = state.reports.findIndex((r) => r.id === id)

      logger.warn('reportFailed', payload)
      if (idx >= 0) {
        const report = {
          ...state.reports[idx],
          error,
          failed: true,
          finished: true,
        }

        state.reports.splice(idx, 1, report)
      } else {
        logger.warn(
          `mutation:reportComplete Unable to find queued report`,
          state.reports,
        )
      }
    },
    reportVersion(state, id) {
      logger.info('reportVersion', id)
      const report =
        state.reports[state.reports.findIndex((r) => r.id === id)]

      if (report) {
        report.dataVersion += 1
        state.reports = [...state.reports]
      }
    },
    reset(state, clearReports) {
      state.affiliatedCampaigns = []
      state.edits = []
      state.keywords = []
      state.notes = []
      state.properties = {}
      state.propertiesLoadedAt = null
      if (clearReports) {
        state.spendDetails = null
        state.spendDetailsLoading = false
        state.spendProjections = null
        state.spendProjectionsLoading = false
        state.reports = []
        state.submittingReport = false
      }
    },
    resetProperties(state) {
      state.properties = {}
    },
    setNotes(state, payload) {
      logger.info(`setNotes ${payload.length}`)
      state.notes = [...payload]
    },
    spendDetails(state, payload = null) {
      logger.info('spendDetails', payload)
      state.spendDetails = payload
    },
    spendProjections(state, payload = null) {
      state.spendProjections = payload
    },
  },
  actions: {
    async addKeywords(_context, { campaignId, keywords }) {
      const {
        data: { success },
      } = await Actions.keywords.add({
        campaignId,
        keywords,
      })

      return success
    },
    async addNote({ dispatch, state }, { shared, text }) {
      const {
        data: { success },
      } = await Actions.notes.add({
        campaignId: state.id,
        shared,
        text,
      })
      await dispatch('getNotes')

      return success
    },
    cancelRunningReports({ commit, getters }) {
      getters.runningReports.forEach(({ id }) =>
        commit('cancelReport', id),
      )
    },
    cancelReport({ commit }, reportId) {
      commit('cancelReport', reportId)
    },
    clear({ commit }) {
      commit('reset', true)
      commit('id')
      return true
    },
    async getAdvertiserDetails({ commit, state }) {
      commit('loadingProperty', {
        loading: true,
        property: 'advertiser',
      })
      commit('property', {
        attribute: 'advertiser',
        payload: null,
      })
      const {
        data: { advertiser },
      } = await getCampaignAdvertiser(state.id)
      logger.info('advertiser got', advertiser)
      commit('property', {
        attribute: 'advertiser',
        payload: advertiser,
      })
      commit('loadingProperty', {
        loading: false,
        property: 'advertiser',
      })
    },
    async getAffiliatedCampaigns({ commit, state }) {
      commit('loading', true)

      const {
        data: { campaigns },
      } = await Actions.getAffiliatedCampaigns({
        campaignId: state.id,
      })

      commit('affiliatedCampaigns', campaigns)
      commit('loading', false)

      return campaigns
    },
    async getEdits({ commit, state }, daysBack = 2) {
      const {
        data: { edits },
      } = await Actions.edits.get({
        campaignIds: [state.id],
        daysBack,
      })
      commit('edits', edits)
      return true
    },
    async getKeywords({ commit, state }) {
      const {
        data: { keywords },
      } = await Actions.keywords.getAll(state.id)
      commit('keywords', keywords)
      return true
    },
    async getMarginDetails({ commit, state }) {
      const { data: marginDetails } = await Actions.margins.get(
        state.id,
      )

      commit('property', {
        attribute: 'marginDetails',
        payload: marginDetails,
      })
      commit('property', {
        attribute: 'hasMarginDetails',
        payload: marginDetails.hasMarginDetails,
      })
    },
    async getNotes({ commit, state }) {
      commit('loadingProperty', { property: 'notes', loading: true })
      const { data: notes = [] } = await Actions.notes.get(state.id)
      logger.info('got notes', notes)
      commit('setNotes', notes)
      commit('loadingProperty', { property: 'notes', loading: false })
    },
    async getProperties(
      { commit, state },
      { quick = false, clear: clearReports = false } = {},
    ) {
      let properties
      logger.info('getProperties', {
        id: state.id,
        quick,
        clearReports,
      })
      commit('loading', true)
      try {
        const { data } = await Actions.getCampaignDetails(
          state.id,
          quick,
        )
        commit('reset', clearReports)
        commit('properties', {
          ...data,
          quick,
        })
        properties = data
      } catch (error) {
        logger.warn('Campaign details', error)
      }
      commit('loading', false)

      return properties
    },
    async getSpendAnalysis({ dispatch, state }) {
      if (!state.spendProjections) {
        await dispatch('getSpendProjections')
      }
      if (!state.spendDetails) {
        await dispatch('getSpendDetails')
      }
    },
    async getSpendProjections({ commit, state }) {
      state.spendProjectionsLoading = true
      const { data } = await getSpendProjections(state.id)
      logger.info('getSpendProjections', data)
      commit('spendProjections', data)
      state.spendProjectionsLoading = false
      return data
    },
    async getSpendDetails({ commit, state }) {
      state.spendDetailsLoading = true
      const { data } = await getSpendDetails(state.id)
      commit('spendDetails', data)
      state.spendDetailsLoading = false
      return data
    },
    async removeNote({ dispatch, state }, noteId) {
      await Actions.notes.remove({
        campaignId: state.id,
        noteId,
      })
      dispatch('getNotes')
    },
    async select(
      { commit, dispatch },
      { campaignId, quick = false },
    ) {
      logger.info(`select`, { campaignId, quick })
      commit('reset')
      commit('resetProperties')
      commit('id', campaignId)
      await addCampaignToRecents({ id: campaignId })
      return dispatch('getProperties', { quick, clear: true })
    },
    [`socket:${socketComponent}_reportComplete`]: {
      root: true,
      async handler({ commit }, { report }) {
        logger.info('socket_reportComplete', report.id)
        commit('reportComplete', report)
        await Actions.acknowledgeReport(report.id)
      },
    },
    [`socket:${socketComponent}_reportFailed`]: {
      root: true,
      async handler({ commit }, payload) {
        const { report: reportData, error } = payload
        logger.warn('socket_reportFailed', payload)
        commit('reportFailed', { ...reportData, error })
        await Actions.acknowledgeReport(reportData.id)
      },
    },
    async resubmitReport({ commit, dispatch, state }, reportId) {
      const report = state.reports.find((r) => r.id === reportId)

      if (report) {
        commit('cancelReport', reportId)
        dispatch('submitReport', {
          ...report,
          dataVersion: 1,
          failed: false,
          finished: false,
          listerOptions: {
            ...report.listerOptions,
            enabled: false,
          },
          resubmitted: true,
        })
      }
    },
    async submitReport({ commit }, report) {
      commit('report', report)

      const { options } = report
      const {
        data: { autoDatesLabel, autoDatesReason, reportId },
      } = await Actions.getReport(options)
      commit('reportId', {
        id: reportId,
        tempId: report.tempId,
        autoDatesLabel,
        autoDatesReason,
      })
    },
    async runReports({ dispatch, rootState, state }, options) {
      const {
        type,
        rangeAutoDate,
        rangeFrom,
        rangeTo,
        rangeType,
        reportType = type,
      } = options
      const reportTypes = getGroupReportTypes({
        campaignTypeId: state.properties.campaign_type_id,
        campaignGoalType: state.properties.goal_type,
        reportType,
      })
      const socketId = rootState.socket.socketId
      let idx = 0
      logger.info('submitting', reportTypes, options, socketId)

      for (const reportType of reportTypes) {
        idx++
        await dispatch('submitReport', {
          title: `${standardiseString(reportType)}`,
          name: reportType,
          tempId: `${reportType}:${Date.now()}:${socketId}:${idx}`,
          finished: false,
          dataVersion: 1,
          selectedRange: rangeType,
          options: {
            autoDate: rangeAutoDate,
            campaign_id: state.id,
            company_id: state.properties.company_id,
            from: `${rangeFrom}`,
            reportType,
            spanMethod: rangeType,
            socketId,
            to: `${rangeTo}`,
          },
        })
      }
      // Also retrieve factors and model attribs
      await dispatch(
        'factors/getIfNotLoaded',
        state.id,
        { root: true }
      )
    },
    async updateMargin({ state }, { rate, marginTypeId }) {
      const {
        data: { success },
      } = await Actions.margins.update({
        campaignId: state.id,
        marginTypeId,
        rate,
      })
      return success
    },
  },
}
