import fullCellPointerStyle from './fullCellPointerStyle'

export default (val, { item, plainText }) => {
  if (plainText) {
    return item.commentCount
  }
  const titleStr = item.commentCount > 0
    ? item.comments
      .map(({ text, custom, author, dateString }) => (
        custom
          ? `On ${dateString}, by ${author}:\n${text}`
          : `Untracked:\n${text}`
      ))
      .join(`\n\n`)
    : 'No comment'
  const fontStyle = (
    item.commentCount > 0 ? 'font-weight: bold;' : 'opacity: 0.5;'
  )

  return (
    `<div ` +
      `style='${fullCellPointerStyle}; ${fontStyle}' ` +
      `title="${titleStr}">` +
        `${item.commentCount}` +
    `</div>`
  )
}
