import Ui from './Ui.vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { mountVuePage } from '../mountVuePage'
import { TestSheetCombinedStore } from './TestSheetCombinedStore'

mountVuePage({
  store: TestSheetCombinedStore,
  socketComponents: ['sheet'],
  rootComponent: Ui,
})
