export default (
  _v,
  {
    item: {
      activeAdsCount,
      activeInvalidAdsCount,
      allActiveAdsAreValid,
      noActiveAdsAreValid,
    },
    plainText,
  },
) => {
  if (plainText) {
    return (
      `${activeAdsCount} active | ` +
      `${activeInvalidAdsCount} not live`
    )
  }
  const style = allActiveAdsAreValid
    ? 'font-weight: normal;'
    : 'font-weight: bold;'

  return (
    `<span class='${
      noActiveAdsAreValid ? 'text-warning' : 'text-secondary'
    }' style='${style}' title='The campaign has ` +
    `${activeAdsCount} active ad${
      activeAdsCount !== 1 ? 's' : ''
    }'>` +
    `${activeAdsCount}${
      activeInvalidAdsCount > 0
        ? ` (${activeInvalidAdsCount} not live)`
        : ''
    }` +
    `</span>`
  )
}
