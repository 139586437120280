import { getSocketServer } from './getSocketServer'
import { getCurrentUser } from './getCurrentUser'
import { getQueryString } from './getQueryString'

export async function getSocketConnectionUrl(component = 'common') {
  const socketServer = await getSocketServer()
  const currentUser = await getCurrentUser()
  const queryString = getQueryString({
    currentUserEmail: currentUser.email,
    role: 'subscriber'
  })
  const result = (
    component === 'common'
      ? `${socketServer}/?${queryString}`
      : `${socketServer}/${component}?${queryString}`
  )

  return result
}
