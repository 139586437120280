import Emitter from './Emitter'
import Socket from 'socket.io-client'

export default class {
  constructor({ component, connection, store }) {
    this.component = component
    if (typeof connection === 'string') {
      this.Socket = Socket(connection, {
        reconnection: true,
        reconnectionDelay: 10000,
        reconnectionAttempts: 10,
        reconnectionDelayMax: 20000,
      })
    } else {
      this.Socket = connection
    }
    if (store) this.store = store
    this.onEvent()
  }

  get actionNamePrefix() {
    return `socket:${this.component}_`
  }

  get mutationNamePrefix() {
    return `SOCKET:${this.component}_`
  }

  onEvent() {
    const super_onevent = this.Socket.onevent
    this.Socket.onevent = (packet) => {
      super_onevent.call(this.Socket, packet)
      const [channel, payload] = packet.data
      Emitter.emit(channel, payload)
      if (this.store) {
        this.passToStore(
          `${this.mutationNamePrefix}${channel}`,
          payload,
        )
      }
    }

    ;[
      'connect',
      'error',
      'disconnect',
      'reconnect',
      'reconnect_attempt',
      'reconnecting',
      'reconnect_error',
      'reconnect_failed',
      'connect_error',
      'connect_timeout',
      'connecting',
      'ping',
      'pong',
    ].forEach((value) => {
      this.Socket.on(value, (data) => {
        Emitter.emit(value, data)
        if (this.store) {
          this.passToStore(`${this.mutationNamePrefix}${value}`, data)
        }
      })
    })
  }

  passToStore(event, payload) {
    if (!event.startsWith(this.mutationNamePrefix)) return

    for (const namespaced in this.store._mutations) {
      const mutation = namespaced.split('/').pop()
      if (mutation === event.toUpperCase()) {
        this.store.commit(namespaced, payload)
      }
    }

    const actionName = event
      .replace(this.mutationNamePrefix, '')
      .toLowerCase()
      .replace(/[\W\s_]+(\w)/g, (_match, p1) => p1.toUpperCase())

    const camelcased = `${this.actionNamePrefix}${actionName}`

    for (const namespaced in this.store._actions) {
      const action = namespaced.split('/').pop()

      if (!action.startsWith(this.actionNamePrefix)) continue
      if (action === camelcased) {
        this.store.dispatch(namespaced, payload)
      }
    }
  }
}
