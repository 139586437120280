const formatPercentage = require('../../../format/percentage')
const formatPercentageTopped = require('../../../format/percentageTopped')

/**
 * Returns a formatted cell value with progress bar
 */

export default ({
  value = 0,
  loaded = value >= 0,
  className = 'cell-progress-bar',
  backgroundColor = '#eee',
  height = 3,
  plainText = false,
}) => {
  const relative = (value >= 0 ? value / 100 : 0)
  if (plainText) {
    return formatPercentage.int(relative)
  }

  const width = formatPercentageTopped(Math.max(0.01, relative))
  const barBSClass = (relative >= 1
    ? 'bg-success'
    : (
        relative <= 0 ? 'bg-danger' : 'bg-default'
      ))

  return loaded
    ? (
      `<span>${formatPercentage.int(relative)}</span>` +
      `<div class="${className}" style="position:relative;bottom:${height + 1}px;` +
        `height:${height}px;width:100%;background-color:${backgroundColor};">` +
        `<div class="${barBSClass}" style="height:100%;width:${width};"></div>` +
      `</div>`
      )
    : '◦'
}
