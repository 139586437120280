import { refreshTimestamp } from '../../campaigns/campaign-constants'
import fullCellPointerStyle from './fullCellPointerStyle'
/**
 * Returns a formatted cell value for row actions
 */

export default (_v, { item: data, plainText }) => {
  const now = Date.now()
  const since = (now - data[refreshTimestamp])
  const mins = parseInt(since / (1000 * 60))
  if (plainText) {
    return data.rowUpdating ? '●' : ''
  }

  return data.rowUpdating
    ? '●'
    : (
      `<div ` +
        `style='${fullCellPointerStyle}' ` +
        `title='Refresh${mins > 1 ? ` (${mins}m ago)` : ''} ` +
        `rank [${data.sortVal}]'` +
      `>&#9675;</div>`
      )
}
